<template>
  <section class="section-container mt-8">
    <v-row class="mb-12">
      <v-col class="section-info col-12 d-flex justify-center">
        <h1 class="section-header  text-md-h2  text-h3 text-center primary--text">
          Localização
        </h1>
      </v-col>

      <v-col class="col-12 iframe-maps">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3727.6608639527094!2d-51.36585508445297!3d-20.885715374131568!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9490ca8341b85ae9%3A0x2d41984918d82207!2sHotel%20Roda%20D&#39;%C3%A1gua!5e0!3m2!1spt-BR!2sbr!4v1665244613534!5m2!1spt-BR!2sbr"
          height="450"
          style="border: 0"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </v-col>

      <v-col>
        <ul>
          <li class="p-1">1,5km do Parque Acquático Acqualinda</li>
          <li class="p-1">700m do Oeste Plaza Shopping</li>
          <li class="p-1">2km do centro da cidade</li>
          <li class="p-1">40 km do aeroporto de Três Lagoas</li>
          <li class="p-1">100 km do aeroporto de Araçatuba</li>
        </ul>
      </v-col>
    </v-row>
  </section>
</template>

<style lang="scss">
.iframe-maps {
  iframe {
    width: calc(100vw - 80px);
    margin-left: -48px;
    max-width: var(--max-width-container);
  }
}
</style>

<script>
export default {
  name: "TheLocalizacao",
};
</script>