<template>
  <div class="home">
    <TheHero />
      <SobreHotel />
      <SobreQuartos />
      <SobreAtividades />
      <SobreRestaurante />
      <TheLocalizacao id="localizacao"/>
  </div>
</template>

<script>
// @ is an alias to /src
import TheHero from "@/components/TheHero.vue";
import TheLocalizacao from "@/components/TheLocalizacao.vue";
import SobreHotel from "@/components/SobreHotel.vue";
import SobreRestaurante from "@/components/SobreRestaurante.vue";
import SobreAtividades from "@/components/SobreAtividades.vue";
import SobreQuartos from "@/components/SobreQuartos.vue";

export default {
  name: 'Home',
    metaInfo: {
      title: 'Inicio',
      titleTemplate: `%s | Roda d'Água`,
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        {
          property: 'og:title',
          content: "Página inicial",
          template: `%s | Roda d'Água`,
          vmid: 'og:title'
        }
      ],
    link: [
      {rel: 'canonical', href: 'https://www.hotelrodadagua.com.br/'}
    ]
    },

  components: {
    TheHero,
    SobreHotel,
    SobreRestaurante,
    SobreAtividades,
    SobreQuartos,
    TheLocalizacao,
  },
  created(){
    this.$emit('carregado')
  }
  }
</script>
