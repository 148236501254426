<template>
  <v-app :style="` ${loading ? 'overflow-y:hidden; height: 100vh;' : ''}`">
    <div class="loader primary" v-if="loading">
      <div class="contener_general">
        <div class="contener_mixte">
          <div class="ballcolor ball_1">&nbsp;</div>
        </div>
        <div class="contener_mixte">
          <div class="ballcolor ball_2">&nbsp;</div>
        </div>
        <div class="contener_mixte">
          <div class="ballcolor ball_3">&nbsp;</div>
        </div>
        <div class="contener_mixte">
          <div class="ballcolor ball_4">&nbsp;</div>
        </div>
      </div>
    </div>

    <v-btn
      color="secondary"
      @click.stop="drawer = !drawer"
      class="menu-btn"
      app
      depressed
      style="position: fixed"
    >
      <v-icon>mdi-menu</v-icon>
    </v-btn>

    <v-navigation-drawer
      v-model="drawer"
      app
      class="primary"
      dark
      elevation="4"
      mobile-breakpoint="700"
      style="box-shadow: -4px 0px 12px black; max-height: 100%; z-index: 10"
    >
      <template v-slot:prepend>
        <div class="logo-menu-wrap">
          <v-img
            class="logo-menu"
            lazy-src="./assets/logo.png"
            max-width="200"
            src="./assets/logo.png"
          ></v-img>
        </div>
      </template>
      <v-list-item-group v-model="selectedItem">
        <v-list
          style="
            align-self: flex-start;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            max-height: 467px;
            height: 60vh;
          "
        >
          <v-list-item v-for="item in items" :key="item.title" link @click="navegar(item.route)">
            <v-list-item-content >
              <v-list-item-title class="item-menu">{{
                item.title
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-list-item-group>

      <template v-slot:append>
        <div class="d-flex justify-center">
          <v-btn class="mt-2 mb-4" color="secondary" tile @click="abrirWhats()">
            Faça sua reserva
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-main>
      <router-view @carregado="pageCarregada()"></router-view>
    </v-main>

    <!-- TODO: RECALCULAR TAMANHO ON RESIZE -->
    <v-footer app absolute inset class="primary d-flex justify-center">
      <the-footer></the-footer>
    </v-footer>
  </v-app>
</template>

<script>
import TheFooter from "@/components/TheFooter.vue";

export default {
  components: { TheFooter },
  name: "App",
  metaInfo: {
    meta: [
      {'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8'},
      {name: 'viewport', content: 'width=device-width, initial-scale=1'},
      {name: 'description', content: 'O Hotel Roda D’Água está há mais de 20 anos oferecendo uma estadia segura e confortável aos seus hóspedes.  Faça sua reserva hoje mesmo.'},
      {name: 'keywords', content: 'Hotel, Andradina, Natureza, estadia, 5 estrelas, conforto'},

    {property: 'og:title', content: "HOTEL RODA D'ÁGUA"},
    {property: 'og:site_name', content: "HOTEL RODA D'ÁGUA"},
    {property: 'og:type', content: 'website'},

    // Should the the same as your canonical link, see below.
    {property: 'og:url', content: 'https://www.hotelrodadagua.com.br/'},
    // {property: 'og:image', content: 'https://www.hotelrodadagua.com.br/ogimg.jpg'},

    // Twitter card
    {name: 'twitter:card', content: 'summary'},
    {name: 'twitter:site', content: 'https://www.hotelrodadagua.com.br/'},
    {name: 'twitter:title', content: "HOTEL RODA D'ÁGUA"},

    // Google / Schema.org markup:
    {itemprop: 'name', content: "HOTEL RODA D'ÁGUA"},
    {itemprop: 'description', content: 'O Hotel Roda D’Água está há mais de 20 anos oferecendo uma estadia segura e confortável aos seus hóspedes.  Faça sua reserva hoje mesmo.'},
    {itemprop: 'image', content: 'https://www.hotelrodadagua.com.br/ogimg.jpg'},
    
    ],
    link: [
      {rel: 'canonical', href: 'https://www.hotelrodadagua.com.br/'}
    ]
  },

  data: () => ({
    timer: null,
    carregado: false,
    loading: true,
    //
    selectedItem: 0,
    drawer: true,
    items: [
      {
        title: "Hotel",
        icon: "mdi-view-dashboard",
        route: { tipo: "router", target: { name: "home", hash: "#home" } },
      },
      {
        title: "Quartos",
        icon: "mdi-view-dashboard",
        route: { tipo: "router", target: { name: "quartos" } },
      },
      {
        title: "Restaurante",
        icon: "mdi-view-dashboard",
        route: { tipo: "router", target: { name: "restaurante" } },
      },
      {
        title: "Localização",
        icon: "mdi-view-dashboard",
        route: {
          tipo: "router",
          target: { name: "home", hash: "#localizacao" },
        },
      },
      {
        title: "Contato",
        icon: "mdi-view-dashboard",
        route: {
          tipo: "scroll",
          target: "#footer",
        },
      },
    ],
  }),
  mounted: function () {
    this.$nextTick(function () {
      this.carregado = false;
    });
  },
  created() {
    const vue = this;
    const width =  window.screen.width
    if(width <= 700) this.drawer = false
    this.timer = setInterval(() => {
      if (vue.carregado === false) {
        clearInterval(vue.timer);
        vue.loading = false;
      }
    }, 2000);
    this.selecionarMenu()
  },
  methods: {
    selecionarMenu(){
      const rota = window.location.pathname + this.$route.hash
      switch(rota){
        case '/#home':
          this.selectedItem = 0
        break;
        case '/quartos':
          this.selectedItem = 1
        break;
        case '/restaurante':
          this.selectedItem = 2
        break;
        case '/#localizacao':
          this.selectedItem = 3
        break;
        default:
          this.selectedItem = 0
        break;
      }
        
      
    },
    abrirWhats(){
      window.open("https://wa.me/5518996992228");
    },
    pageCarregada(){
      this.loading = false
    },
    navegar(rota) {
      this.loading = true;
      if (rota.tipo === "scroll") {
        this.$vuetify.goTo(rota.target, {
          duration: 500,
          offset: 0,
          easing: "easeInOutCubic",
        });
      } else {
        this.$router.push(rota.target);
      }
      this.loading = false;

    },
  },
};
</script>

<style lang="scss">
.logo-menu-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.logo-menu {
  margin: 12px 8px;
}
.item-menu {
  font-size: 28px;
  margin-left: 8px;
  font-family: "Fauna One", sans-serif;
  color: white !important;
  // color: var(--v-secondary-base) !important;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #3e4735;
  border: 0px solid #ffffff;
  border-radius: 50px;
  transition: all 0.5s;
}
::-webkit-scrollbar-thumb:hover {
  background: #242a1f;
  transition: all 0.5s;
}
::-webkit-scrollbar-thumb:active {
  background: #d2ae6d;
}
::-webkit-scrollbar-track {
  background: #666666;
  border: 0px none #ffffff;
  border-radius: 8px;
}
::-webkit-scrollbar-track:hover {
  background: #666666;
}
::-webkit-scrollbar-track:active {
  background: #333333;
}
::-webkit-scrollbar-corner {
  background: transparent;
}
</style>