<template>
  <section class="section-container mt-8 pb-16 flex-column align-center">
    <v-row class="mb-12">
      <v-col class="section-info col-12 d-flex flex-column align-center">
        <h1 class="section-header text-md-h2  text-h3 text-center primary--text">
          Atividades
        </h1>
      </v-col>
    </v-row>

    <v-row class="section-imgs justify-space-around col-12">
      <v-col class="item-atividade  col-12 col-sm-10 col-md-5 col-lg-4">
        <div class="atividade-img">
          <img src="../assets/home/atividades-1.jpg"/>
        </div>

        <p class="atividade-desc p-2 ma-4">
          Movimento é vida. Conte com nossa academia para manter seus cuidados
          com a saúde enquanto estiver hospedado conosco e o melhor, já está
          incluso no valor da diária.
        </p>
      </v-col>

      <v-col class="item-atividade col-12 col-sm-10 col-md-5 col-lg-4">
        <div class="atividade-img">
          <img src="../assets/home/atividades-2.jpg"/>
        </div>

        <p class="atividade-desc p-2 ma-4">
          O espaço da piscina aquecida é estruturado com espreguiçadeiras e
          quiosques, proporcionando momentos relaxantes ou de pura diversão para
          você e sua família.
        </p>
      </v-col>

      <v-col class="item-atividade col-12 col-sm-10 col-md-5 col-lg-4">
        <div class="atividade-img">
          <img src="../assets/home/atividades-3.jpg"/>
        </div>

        <p class="atividade-desc p-2 ma-4">
          Alecrim semijoias é uma loja de acessórios dentro do nosso hotel, onde você encontrará peças de designers brasileiros feitas com pedras naturais, resinas entre outros.
        </p>
      </v-col>
    </v-row>
  </section>
</template>

<style lang="scss" >
.item-atividade {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  .atividade-img {
    width: 90%;
    border-radius: 48px;
    overflow: hidden;
    aspect-ratio: 1/1;
    img{
      height: 100%;
    }
  }
  .atividade-desc {
    text-align: center;
  }
}
</style>

<script>
export default {
  name: "SobreAtividades",
};
</script>