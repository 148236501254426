import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import goTo from 'vuetify/lib/services/goto'
import Meta from 'vue-meta'


Vue.use(VueRouter)
Vue.use(Meta, {
  refreshOnceOnNavigation: true
})

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/quartos',
    name: 'quartos',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "quartos" */ '../views/Quartos.vue')
  },
  {
    path: '/restaurante',
    name: 'restaurante',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "restaurante" */ '../views/Restaurante.vue')
  }
]

const router = new VueRouter({
  // eslint-disable-next-line no-unused-vars
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0

    if (to.hash) {
      scrollTo = to.hash
    }
    //  else if(savedPosition) {
    //   scrollTo = savedPosition.y
    // }

    return goTo(scrollTo)
  },
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
