<template>
  <section class="section-container logo-bg mt-8" >
    <v-row class="mb-12 ">
      <v-col class="section-info col-12 col-md-7">

        <div class="d-flex flex-column align-center">
            <img class="preapend-header" src="../assets/tipografia.png"/>
            <h1 class="section-header  text-md-h2  text-h3 text-center primary--text">Bem vindo</h1>
        </div>

        <p class="p-1 mt-8 primary--text text-center text-sm-left">
          O Hotel Roda D’Água está há mais de 20 anos oferecendo uma estada
          segura e confortável aos seus hóspedes. Possui 62 aconchegantes
          apartamentos divididos em diferentes categorias, porém todos equipados
          com Tv de LED, Ar condicionado SPLIT, Ventilador de teto, Frigobar,
          Serviço de enxoval completo, Aquecimento Central, Secador de cabelo,
          Telefone e Serviço de Copa.
        </p>

        <p class="p-1 primary--text text-center text-sm-left">
          O Hotel Roda D’Água está sempre buscando inovações para proporcionar
          conforto e comodidade aos seus hóspedes!
        </p>
      </v-col>

      <v-col class="section-imgs imgs-sobrepostas col-12 col-md-5 col-sm-12">
        <div class="img-back">
        <img
          src="../assets/home/hotel-1.jpg"
          class="imgs"
        />
        </div>
        <div class="img-front">
        <img
          src="../assets/home/hotel-2.jpg"
          class="imgs"
        />
        </div>
      </v-col>
    </v-row>
  </section>
</template>

<script>
export default {
  name: "SobreHotel",
};
</script>