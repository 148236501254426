<template>
  <section class="hero-container" id="home">
    <div
      style="position: absolute; bottom: 0; width: 100%; z-index: 5"
      class="d-flex justify-center pb-4"
    >
      <v-icon style="" class="pa-2 icon-scroll shake-vertical" large
        >mdi-arrow-down</v-icon
      >
    </div>

    <video
      playsinline
      loop="true"
      autoplay
      muted
      style="pointer-events: none"
      class="video-bg"
    >
      <source src="../assets/apresentacao.mp4" type="video/mp4" />
      <!-- <source src="movie.ogg" type="video/ogg"> -->
      Your browser does not support the video tag.
    </video>
  </section>
</template>

<script>
export default {
  name: "HelloWorld",

  data: () => ({}),
};
</script>

<style lang="scss">
.hero-container {
  .icon-scroll {
    color: rgba(255, 255, 255, 0.45);
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.45);
    transition: all 0.2s;
    z-index: 2;
    &:hover {
      color: rgba(255, 228, 120, 0.65);
      border: 2px solid rgba(255, 228, 120, 0.45);
    }
  }
  max-height: 100vh;

  width: 100%;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  aspect-ratio: 16/9;
  display: flex;
  justify-content: center;
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    // background-color: #3e4735;
    // // TODO: ESCOLHER UMA TEXTURA MELHOR
    // background-image: url('../assets/noise.png');
    // // background-size: cover;
    background-repeat: repeat;
    // z-index: 4;
    filter: opacity(0.11);
    background:
    radial-gradient(circle at 100% 50%, transparent 20%, rgba(255,255,255,.3) 21%, rgba(255,255,255,.3) 34%, transparent 35%, transparent),
    radial-gradient(circle at 0% 50%, transparent 20%, rgba(255,255,255,.3) 21%, rgba(255,255,255,.3) 34%, transparent 35%, transparent) 0 -50px;
    background-color: var(--primary);
    background-size:11px 20px;
    transform: rotate(90deg) scaleY(2);
  }
}
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
}
.video-bg {
  height: 100%;
  width: auto;
  source {
    height: 100%;
  }
  aspect-ratio: 16/9;
}
</style>
