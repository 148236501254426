<template>
  <section class="section-container mt-8 fundo-cinza">
    <v-row class="mb-12 section-quartos">
      <v-col
        class="section-imgs imgs-left col-sm-12 col-md-8 col-lg-5 d-flex flex-column "
        style="position: relative; align-items: end;     margin-left: -48px;"
      >
        <img style=" " src="../assets/home/quartos.jpg" class="imgs"/>

        <v-btn class="mt-4 " color="primary" dark x-large tile @click="$router.push({name: 'quartos'})"> Conheça todos os quartos </v-btn>

        <v-btn class="mt-4 " color="primary" dark x-large tile @click="abrirWhats()"> Faça sua reserva </v-btn>
      </v-col>

      <v-col class="section-info  col-sm-12 col-md-12 col-lg-7">
        <h1 class="section-header  text-md-h2  text-h3 text-center primary--text">
          Apartamentos
        </h1>

        <p class="p-1 mt-8 primary--text pa-4 pl-12">
          Todos os nossos apartamentos são equipados com wi-fi gratuito, ar
          condicionado Split, ventilador de teto, TV de LED, camas box, enxoval
          de primeira qualidade, edredom, secador de cabelo e amenities. Algumas
          comodidades ainda contam com isolamento acústico nas portas e janelas.
        </p>
        <p class="p-1  primary--text pa-4 pl-12">
          No valor da diária está incluso Café da Manhã completo para um
          excelente começo de dia, Academia para continuar os cuidados com a
          saúde enquanto estiver hospedado, Estacionamento, Piscina aquecida
          para os momentos relaxantes ou de pura diversão para as crianças,
          estruturados com quiosques e espreguiçadeiras.
        </p>
      </v-col>
    </v-row>
  </section>
</template>

<script>
export default {
  name: "SobreQuartos",
  mounted: function () {
  },
  methods: {
    abrirWhats(){
      window.open("https://wa.me/5518996992228");
    },
  },
};
</script>

<style lang="scss">
.fundo-cinza {
  background-color: rgb(246, 246, 246);
}
</style>