<template>
  <section class="section-container mt-0 mt-sm-16 ">
    <v-row class="mb-12 mt-md-10">
      <v-col
        class="
          section-imgs
          imgs-left
          col-sm-12 col-md-6 col-lg-5
          d-flex
          flex-column
        "
        style="position: relative; align-items: end"
      >
        <img src="../assets/home/restaurante.jpg" class="imgs"/>
      </v-col>

      <v-col
        class="
          section-info
          col-sm-12 col-md-6 col-lg-6
          d-flex
          flex-column
          align-center
          justify-center
        "
      >
        <h1 class="section-header  text-md-h2  text-h3 text-center primary--text">
          Restaurante
        </h1>

        <p class="p-1 mt-8 primary--text pa-4 text-center">
          No valor da diária está incluso Café da Manhã completo para um
          excelente começo de dia.
        </p>

        <v-btn class="mt-4" color="primary" dark x-large tile @click="$router.push({name: 'restaurante'})">
          Conheça nossa gastronomia
        </v-btn>
      </v-col>
    </v-row>
  </section>
</template>

<script>
export default {
  name: "SobreRestaurante"
};
</script>