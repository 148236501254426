<template>
  <div class="footer-container  primary logo-bg--footer">
        <div style="grid-area: e / f / f / f" class="d-flex align-center">

          <v-img
            class="logo-menu logo--footer"
            lazy-src="../assets/logo.png"
            src="../assets/logo.png"
          ></v-img>
        </div>

          <p class="p-1 text--white text-sm-right text-center text-md-center " style="grid-area: e / e / e / e">
            Marginal Marechal Rondon, Km 640<br />
            Andradina, SP 16900-000
          </p>

          <div id="footer" class="align-self-end text-right p-1 secondary--text " style="grid-area: c; z-index: 10">
            <span class="label-contato ">CONTATO</span>
            <div class="item-contato secondary--text"><a href="tel:+5518996992228">(18) 99699-2228</a></div>
            <div class="item-contato secondary--text"><a href="tel:+551837222228">(18) 3722-2228</a></div>
            <div class="item-contato secondary--text"><a href="mailto:hotelrodadagua@uol.com.br">hotelrodadagua@uol.com.br</a></div>
          </div>

  </div>
</template>

<style lang="scss">
.footer-container{
    max-width: var(--max-width-container);
    min-height: 20vh;
    width: 100%;
    padding: 24px 12px;
    display: grid;
    grid-template:  "e e e e e"
                    "f c c c c"
                    "f c c c c";
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr ;
    grid-template-rows: 1fr 1fr 1fr;
    p{
        font-size: 20px;
    }
    z-index: 12;
}
.label-contato{
    font-size: 22px;
}
.item-contato{
    margin: 12px 4px;
    font-size: 22px;
    a{
        text-decoration: none;
    }
}
.logo--footer{
    width: 15vw;
    max-width: 400px;
    min-width: 164px;
}
</style>

<script>
export default {
  name: "TheFooter",
};
</script>